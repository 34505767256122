import React from 'react'
import Layout from '../components/Layout'
import { Container, Form, Button, Row } from "react-bootstrap"
import '../styles/career.css'
import { useState, useEffect } from "react";
import AOS from 'aos';
import axios from 'axios';
import Head from '../components/Head';



function Career() {

    const careerValues = {
        name:"",
        phone_number:"",
        email:"",
        qualification:"",
        experience:"" ,
        resume:"" 
    }

    const [career, setCareer] = useState(careerValues);

    const submitCareer = (event) => {
        event.preventDefault();  
        axios.post(`https://sheet.best/api/sheets/6647eb07-a7d1-46bb-bd5a-5e47cb9a197a`,career)

    }


    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);

    return (
        <div className='overflow-hidden'>
            <Head title="Career"/>
            <Layout>
                <Container className="object px-5">

                    
                    <section className='career' >
                        <header className="sectionHeader halfBorder my-5" as="h1">Career</header>

                        <div className="careerForm"  >
                            <Form className='p-5' data-aos="zoom-in" >
                                <Row className="mb-4" >
                                    <Form.Group  className='col-6 ' controlId="formBasicTextName">
                                        <Form.Label>Name</Form.Label>
                                    
                                        <Form.Control
                                            required
                                            className='input'
                                            type="text"
                                            maxlength="20"
                                            placeholder="Your Name"
                                            title="Please enter only alphabhets"
                                            name='name'
                                            value={career.name}
                                            onChange = {(e)=>{setCareer({...career, name:e.target.value})}}

                                        />
                                    </Form.Group>
                                    <Form.Group  className='col-6' controlId="formBasicTextNumber">
                                        <Form.Label>Phone Number</Form.Label>

                                        <Form.Control
                                        required
                                        className='input'
                                        type="text"
                                        pattern="[0-9]{1}[0-9]{9}"
                                        placeholder="Your Phone Number" 
                                        title="Please enter 10 digit Mobile Number"
                                        name='phone_number'
                                        value={career.phone_number}
                                        onChange = {(e)=>{setCareer({...career, phone_number:e.target.value})}}
                                        />
                                    </Form.Group>
                                    <Form.Group  className='col-6' controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        
                                        <Form.Control
                                            required
                                            className='input'
                                            type="email"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            placeholder="Your Email Address"
                                            title="Please enter Email Address with correct format"
                                            name='email'  
                                            value={career.email}
                                            onChange = {(e)=>{setCareer({...career, email:e.target.value})}}
                                        />
                                    </Form.Group>
                                    <Form.Group  className='col-6 ' controlId="formBasicTextName">
                                        <Form.Label>Qualification</Form.Label>
                                    
                                        <Form.Control
                                            required
                                            className='input'
                                            type="text"
                                            maxlength="10"
                                            placeholder="Your Qualification"
                                            title="Please enter Your Qualification"
                                            name='qualification'
                                            value={career.qualification}
                                            onChange = {(e)=>{setCareer({...career, qualification:e.target.value})}}

                                        />
                                    </Form.Group>
                                    <Form.Group  className='col-6' controlId="formBasicTextNumber">
                                        <Form.Label>Experience</Form.Label>

                                        <Form.Control
                                            required
                                            className='input'
                                            type="number"
                                            min="1" 
                                            max="50"
                                            step={1}
                                            placeholder="Your Experience in years " 
                                            title="Please enter Experience"
                                            name='experience'
                                            value={career.experience}
                                            onChange = {(e)=>{setCareer({...career, experience:e.target.value})}}
                                        />
                                    </Form.Group>

                                    <Form.Group className='col-6 mb-4'controlId="formFile">
                                        <Form.Label>Resume </Form.Label>
                                        <Form.Control 
                                            required
                                            type="file"   
                                            accept="application/pdf,application/doc" 
                                            name='resume'
                                            value={career.resume}
                                            onChange = {(e)=>{setCareer({...career, resume:e.target.value})}}
                                        />
                                    </Form.Group>
                                </Row>
                                <div className="careerButtons">
                                    <Button 
                                        className="submitBtn btn me-4 w-25 "  
                                        type="submit"  
                                        style={{fontSize:"2rem"}}
                                        onClick= {submitCareer}
                                    >
                                        Submit 
                                    </Button>
                                    <Button className=" resetBtn btn ms-4  w-25"  type="reset" style={{fontSize:'2rem'}}>Reset</Button>
                                </div>
                            </Form>
                        </div>

                        <div className="careerBg">
                        </div>
                        
                    </section>
        
                </Container>

            </Layout>
            
        </div>
    )
}

export default Career

